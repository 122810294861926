module.exports = {
  'nav': {
    'lead':'モニタリングシステムのオールインワンパッケージ',
    'about':'HARPS&trade;について',
    'introduction':'<i class="fa fa-file-pdf-o"></i> ご紹介 (英語)',
    'howtojoin':'導入までの流れ (英語)',
    'howtojoin2':'How to join',
    'help':'ヘルプと参考情報',
    'environment':'サポート環境',
    'quick':'クイックスタートガイド (英語)',
    'basic':'基本操作',
    'faq':'よくある質問',
    'items':'製品情報',
    'contact':'お問い合わせ',
    'signup':'新規登録',
    'device':'デバイス情報',
    'iotizr':'IoTIZR&trade;',
    'options': 'オプション(英語)',
    'tds_tech_doc':'簡易TDSセンサー(英語)',
    'login':'ログイン',
    'video':'設置ビデオ',
    'flow':'流量センサー編(英語)',
    'tds':'簡易TDSセンサー編(英語)',
    'manual':'マニュアル',
    'dashboard_manual':'Dashboardマニュアル(英語)',
    'iotizr_manual':'IoTIZR&trade; 設定マニュアル(英語)',
    'price':'料金'
  },
  'top': {
    'lead':'水と、つながる。ビジネスは、もう一段階上へ。',
    'text':'HARPS&trade;で様々な水処理システムの使用情報を賢く管理',
  },
  'signup': {
    'title':'プレビュー版のご利用',
    'lead':'HARPS&trade;プレビュー版デバイスキットをお持ちの方',
    'text':'使用開始はこちら',
    'signup':'新規登録'
  },
  'topAttention': {
    'title':'ご案内',
    'lead':'<p class="top-attention-lead">HARPS&trade;プレビュー版は2018年3月22日に終了します</p>',
    'text':'詳しくはこちら'
  },
  'main1': {
    'lead':'インターネットとつながる水処理システムのオールインワンパッケージ',

  },
  'main2': {
    'lead':'HARPSが提供するもの',
    'text1':'ハードウエア',
    'text2':'BLE-WLAN トランスミッター "IoTIZR &trade;", センサー',
    'text3':'サービス',
    'text4':'Webアプリ、モバイルアプリ',
    'text5':'オプション：カスタマイズ',
    'text6':'画面のデザイン変更',
    'text7':'接続先サーバー',
    'text8':'センサー追加'
  },
  'main3': {
    'lead':'様々なデータはビジネスへのインスピレーションに'
  },
  'main4': {
    'lead':'',
    'button':'お問い合わせ',
  },
  'attention': {
    'title':'お知らせ',
    'lead':'プレビュー版のご利用について',
    'text':'<p>2018年4月1日以降、HARPS&trade; 正式版のリリースを予定しております。</p><p>これに伴い、HARPS&trade; プレビュー版のご提供を2018年3月22日をもって終了とさせて頂きます。</p>また、HARPS&trade;プレビュー版からHARPS&trade;正式版へのデータ移行はできません。</p><p>何卒ご理解を賜りますようお願い申し上げます。</p>',
    'back':'Back'
  },
  'about1': {
    'lead':'HARPS&trade;について',
    'head':'インターネットとつながる水処理システム。モニタリングのためのオールインワンパッケージ',
    'box':'HARPS&trade;は、インターネットを通じて水処理システムのモニタリングを可能にするサービスです。スマートフォンアプリとWeb管理画面でいつでも使用状況を確認することができます。これにより、フィルター類の交換スケジュールなどを簡単に管理することができ、クラウドに保存されたデータや情報をビジネスに有効活用することができます。'
  },
  'about2': {
    'lead':'セットアップは簡単！',
    'text':'対応機器の用意は不要。全てが入ったオールインワン製品です。',
    'item1':'デバイス',
    'item2':'スマートフォンアプリ',
    'item3':'Web管理画面'
  },
  'about3': {
    'title':'デバイス - IoTIZR&trade;',
    'text':'<p>IoTIZR&trade;(アイオータイザー)はBluetoothと無線LAN対応の小型通信デバイスです。 IoTIZR&trade;は水処理システムの状態を取得する各種センサーと接続され、そのデータがインターネットを介してクラウドに送信されます。そして、これらの情報にスマートフォンアプリやWeb管理画面上からアクセスすることができます。IoTIZR&trade;のセットアップは、スマートフォンアプリから簡単に行うことができます。</p>'
  },
  'about4': {
    'title':'スマートフォンアプリ',
    'text':'<p>iOS、Android対応スマートフォンアプリにはさまざまな機能があります。IoTIZR&trade;のセットアップはこのアプリで行います。そのため、使用開始前にアプリのダウンロードが必要となります。</p>',
    'usage1':'設定など',
    'detail1':'<p>IoTIZR&trade;のセットアップ・Wi-Fiアクセスポイントの変更</p>',
    'detail2':'フィルター類の交換',
    'usage2':'モバイル顧客リスト',
    'detail3':'通知を表示',
    'detail4':'連絡先の確認(地図をタップすると地図アプリが開きます)',
    'detail5':'水処理システムの状態確認',
  },
  'about5': {
    'title':'Web管理画面',
    'text':'顧客と水処理システムの状態を管理。<br>ウェブ上で水の使用状況の詳細を確認することができます。'
  },
  'contact': {
    'title':'お問い合わせ',
    'name':'お名前',
    'email':'メールアドレス',
    'confirm':'確認用メールアドレス',
    'company':'会社名・団体名',
    'position':'役職名',
    'tel':'電話番号',
    'detail':'お問い合わせ内容',
    'submit':'送信'
  },
  'thanks': {
    'text1':'お問い合わせありがとうございます。',
    'text2':'このたびは、お問い合わせいただき、誠にありがとうございました。<br>お送りいただきました内容を確認の上、担当者より折り返しご連絡させていただきます。',
    'text3':'※お問い合わせの内容により、回答にお時間を頂く場合がございます。ご迷惑をおかけいたしますが、ご理解の程よろしくお願いいたします。'
  },
  'environment': {
    'header1':'対応する浄水システム',
    'data1':'交換フィルター(時間寿命、浄水量寿命をもつもの)を用いた浄水システム。',
    'header2':'流量センサー',
    'data2':"Savant FS-2200H-XHP ( <a href=\'http://tw.digisavant.com/PDF/FS-2200H-Spec.pdf\' target='_blank'>Specification</a> )<br><h4>このセンサーがお客様の環境に合いませんか？<a href=\'https://www.aplix.co.jp/inquiry/product/\' target='_blank'>お問い合わせください</a> </h4>",
    'header3':'スマートフォンアプリ',
    'data3iphone':"<h3>iPhone</h3><p>iOS9.0以上</p>",
    'data3android':"<h3>Android</h3><p>Android4.4以上</p><h4>※一部の端末で不具合が出る可能性があります。</h4>",
    'header4':"Web管理画面",
    'data4':"Google Chrome,Safari,Internet Explorerの最新版"
  },
  'quickMenu': {
    'step1':'ステップ 1',
    'step2':'ステップ 2',
    'step3':'ステップ 3',
    'step4':'ステップ 4',
    'step5':'ステップ 5',
    'step6':'ステップ 6',
    'step1Title':'製品の確認',
    'step2Title':'システムの登録',
    'step3Title':'設置拠点の登録',
    'step4Title':'センサーを接続',
    'step5Title':'IoTIZR&trade;の<br>セットアップ',
    'step6Title':'セットアップ後'
  },
  'step1': {
    'pdf':"<a href=\'../pdf/quickguide_JA.pdf' target='_blank' class='pdf-link btn'><span>クイックスタートガイド<br>PDFドキュメント</span>",
    'step':'ステップ 1 : 次の製品が揃っていることを確認してください。',
    'title1':'流量センサー',
    'text1':"<p>流量センサーは水量を測定する装置です。 浄水システムに接続することで、水の使用量を伝えることができます。</p><p>現行のモデルはSavant FS-2200H-XHPです。 <a href=\'http://tw.digisavant.com/PDF/FS-2200H-Spec.pdf\' target='_blank'>商品の詳細を見る</a></p><p>FS-2200H-XHPがお客様の環境に適合しない場合（部品の形状やサイズ・またはフローレートが合わない場合）、異なる流量センサーが必要となりますので、<a href=\'https://www.aplix.co.jp/inquiry/product/\' target='_blank'>お問い合わせください。</a></p>",
    'title2':'IoTIZR&trade;',
    'text2':'<p>IoTIZR&trade;はインターネットに接続するための機器です。 流量センサーからクラウドにデータを送信し、使用状態をスマートフォンアプリとWeb管理画面に表示します。</p>',
    'title3':'Web管理画面<br>"HARPS&trade; Dashboard"',
    'text4':"<p><a href=\'/aqua.harps4.com/dashboard/signup\' target='_blank'>新規登録</a>より、案内に沿ってアカウントを作成してください。</p>",
    'title4':'スマートフォンアプリ<br>"HARPS&trade; Toolkit"',
    'text5':'<p>IoTIZR&trade;の設定、フィルター類の交換、Wi-Fiアクセスポイントの変更にはスマートフォンアプリが必要です。スマートフォンにアプリをダウンロードし、HARPS&trade;アカウントでログインします。</p>',
    'nextStep':'次のステップ : 浄水システムを登録してください。',
    'next':'次へ'
  },
  'step2': {
    'step':'ステップ2 : 浄水システムを登録してください。',
    'text1':'正確にフィルター交換サイクルを把握するには、浄水システムの詳細（仕様）を登録する必要があります。',
    'text2':'あなたの浄水システムの詳細は、今すぐリクエストフォームから登録することができます。 必要な情報を入力し、リクエストを送信します。登録完了後、数日以内にメールでご連絡いたします。',
    'request':'浄水システム登録用リクエストフォーム',
    'text3':'実際に使用する前にどのように動作するかテストしたい場合は、プリセットの浄水システムを使用できます。',
    'nextStep':'次のステップ：浄水システムの設置拠点を登録してください。',
    'previous':'前へ',
    'next':'次へ'
  },
  'step3': {
    'step':'ステップ3 : システムの設置拠点を登録してください。',
    'stepTitle':'ステップ3:浄水システムの設置拠点を登録してください。',
    'text1':'<p>浄水システムが設置されている住所を登録してください。 IoTIZR&trade;のセットアップには設置拠点の情報が必要です。このステップで設置拠点を登録してください。</p>',
    'text2':'実際に使用する前にどのように動作するかテストしたい場合は、暫定的にお客様のオフィス等の住所をご登録ください。',
    'add':'設置拠点を登録する',
    'text3':'<p>住所登録は上記のフォーム、またはスマートフォンアプリから行うことができます。 はじめに登録することが推奨されますが、IoTIZR&trade;セットアップの際に登録することもできます。</p>',
    'nextStep':'次のステップ:流量センサーを浄水器に接続してください。',
    'previous':'前へ',
    'next':'次へ'
  },
  'step4': {
    'step':'ステップ4 : 流量センサーを浄水器に接続してください。',
    'attention':'以下のプロセスは、実際の設置拠点で操作する必要があります。',
    'text1':'<p>まず、浄水器に流量センサーを取り付けてください。 次に、取り付けた流量センサーにIoTIZR&trade;を接続します。IoTIZR&trade;は水のかからない場所に設置してください。</p>',
    'notice':'注意',
    'text2':'水などの液体がかからない場所に設置してください。',
    'nextStep':'<p class="next-step">次のステップ:IoTIZR&trade;をセットアップする</p>',
    'previous':'前へ',
    'next':'次へ'
  },
  'step5': {
    'step':'<h2 class="StepTitle">ステップ5 : IoTIZR&trade;をセットアップする</h2>',
    'attention':'以下の操作では、スマートフォンアプリ”HARPS&trade; Toolkit”を使用します。',
    'text1':'<p>App Store / Google Playからアプリをダウンロードし、HARPS&trade;アカウントでログインします。</p>',
    'text2':'<p>Wi-FiアクセスポイントをIoTIZR&trade;に設定し、インターネットに接続します。 スマートフォンとIoTIZR&trade;は、設定時に機器同士を近づける必要があります。</p>',
    'text3':'<p>セットアップは、スマートフォンアプリで「+インストール」をタップするか、IoTIZR&trade;で「H」ボタンを押すことで開始されます。 表示された指示に従って、設定/登録を行います。</p>',
    'nextStep':'次のステップ:セットアップ後',
    'previous':'前へ',
    'next':'次へ'
  },
  'step6': {
    'step':'ステップ6 : セットアップ後',
    'text1':'<p>IoTIZR&trade;のセットアップが完了すると、ステータス監視が開始されます。 情報は毎日更新されます。</p>',
    'title1':'フィルター類の交換:',
    'text2':'スマートフォンアプリで「フィルター類の交換」ボタンをタップします。',
    'title2':'Wi-Fiアクセスポイントの変更：',
    'text3':'スマートフォンアプリの[Wi-Fi設定のリセット]ボタンをタップします。',
    'basic':"<p><a href=\'basic_operations.html\' class='large-link' target='_blank'>基本操作</a></p>",
    'previous':'前へ'
  },
  'basic': {
    'function':'「HARPS&trade; プレビュー版」の基本機能',
    'text1':'<p>「HARPS&trade; プレビュー版」は浄水システムの状態を監視し、フィルター類の交換タイミングと警告を通知します。 浄水システムにIoTIZR&trade;を取り付けることにより、一定の時間ごとに使用データをサーバーに送信します。 お客様はスマートフォンアプリやWeb管理画面で利用状況を確認することができます。</p>',
    'related':'関連ページ:',
    'device':'IoTIZR&trade;',
    'settings':'<h2>「HARPS&trade; プレビュー版」の初期設定</h2>',
    'quick':'クイックスタートガイド',
    'check':'フィルター類の状態を確認する',
    'text2':'Web管理画面で設置拠点を選択し、設置拠点の画面でフィルター類を選択します。',
    'app':'スマートフォンアプリ',
    'replacement':'フィルターの交換',
    'text3':'フィルター類の交換時期が過ぎると、スマートフォンアプリ / Web管理画面に通知が表示されます。フィルター類の寿命をリセットするには、スマートフォンアプリで「フィルター類の交換」をタップします。 フィルター類の寿命カウンターがリセットされます。',
    'wifi':'Wi-Fiアクセスポイントの変更',
    'text4':'Wi-Fiアクセスポイントは、スマートフォンアプリのWi-Fi設定から変更できます。',
    'faq':'浄水システムのユーザーはWi-Fiアクセスポイントの設定を変更できますか？',
    'delete':'登録済み浄水システムの削除',
    'text5':'登録済みの浄水システムを削除するには、以下の手順に従ってください。',
    'notice':'注意',
    'text6':'Wi-Fiアクセスポイントを含むデータを完全に消去したい場合は、IoTIZR&trade;本体が必要です。'
  },
  'faq': {
    'faq':'よくある質問',
    'words':'単語について',
    'words1':'「設置拠点」とは何ですか？',
    'words2':'「登録デバイス」とは何ですか？',
    'words3':'「機器」とは何ですか？',
    'words4':'HARPS&trade; Trial Water System',
    'operations':'操作方法',
    'operations1':'Web画面上で水の状態をどのように管理するのか分かりません。',
    'operations2':'浄水システムのユーザー/所有者はWi-Fiアクセスポイントを変更できますか？',
    'operations3':'IoTIZR&trade;からの情報の送信間隔'
  },
  'trouble': {
    'trouble':'トラブルシューティング',
    'trouble1':'水を流した後、HARPS&trade; Web管理画面を確認しても、パラメータやグラフに反映されません。',
    'trouble2':'位置情報を有効にしていても、赤いアラート（「位置情報の設定を変更してください」）が、アプリの上部に表示されます。'
  },
  'answer': {
    'answer1':'「設置拠点」とは、浄水システムが設置されている実際の場所を意味します。設置拠点の登録はWeb管理画面とスマートフォンアプリから行うことができます。はじめに登録しておくことをお勧めしますが、IoTIZR&trade;の初期設定時にスマートフォンアプリから行うこともできます。',
    'answer2':'「登録デバイス」とは、登録され、インターネットに接続されているIoTIZR&trade;を意味します。',
    'answer3':'「機器」は、次の2つの要素に分かれています。<br>1）浄水システム部品<br><li>交換用フィルター</li><li>フィルターキャニスター、ハウジング</li><li>接続機器/部品</li></ul>HARPS&trade;の機能性を素早く確認したい場合は、「HARPS&trade; Trial Water System」を選択してください。<br><br>2）流量センサー<br>「HARPS&trade; プレビュー版」はSavant FS-2200H-XHPをサンプルとして提供しています。',
    'answer4':'この浄水システムは、お客様のテスト目的のために予め登録されています。この仮想浄水システムを選択することで、HARPS&trade;の動作を体験できます。フィルターの寿命は14日 / 50ガロンもしくは約190リットルと定義されています。',
    'answer5':'現在、Wi-Fiアクセスポイントは、アプリ（HARPS&trade; Toolkit）にログインできる浄水システムベンダーによってのみ変更できます。',
    'answer6':'IoTIZR&trade;は、電源を入れた後、またはボタンを押した後は、短い間隔で10分間情報を送信し続けます。 通常は15分ごとに情報を送信します。',
    'answer7':"まず、<a href=\'https://aqua.harps4.com/dashboard/signup\' target='_blank'>登録フォーム</a>から新規登録する必要があります。<br>次に、<a href=\'https://aqua.harps4.com/dashboard/login\' target='_blank' rel='noopener'>ログインページ</a>からHARPS&trade; Dashboardにログインします。<br>その後、 <a href=\'../help/quickstart.html\' target='_blank'>「クイックスタートガイド」</a> を参照してセットアップを進めてください。",
    'trouble1':'水を流した後、HARPS&trade; Web管理画面を確認しても、パラメータやグラフに反映されません。',
    'answer8':'IoTIZR&trade;の電源を入れるかボタンを押してから10分以降は、IoTIZR&trade;からの情報の送信間隔は15分ごとです。<br>最新情報を表示するには、IoTIZR&trade;のボタンを押します。オレンジのLEDが点滅している間はほぼリアルタイムで情報を送信しています。',
    'trouble2':'位置情報を有効にしていても、赤いアラート（「位置情報の設定を変更してください」）が、アプリの上部に表示されます。',
    'answer9':'HARPS&trade; Toolkitでは、位置情報の設定が「常に」になっている必要があります。位置情報が「使用中」に設定されている場合は、「常に」に切り替えてください。'
  },
  'device': {
    'specifications':'仕様',
    'header1':'I/F',
    'data1':'ポート：1つの流量センサーポートと3つの拡張センサーポート<br>LED：3つのLED<br>ボタン：1つのボタン',
    'header2':'電源',
    'data2':'micro USB B（レセプタクル）<br>500mA以上',
    'header3':'サイズ',
    'data3':'L85mm * W50mm * H15mm (標準値)',
    'header4':'重さ',
    'data4':'45g (標準値)',
    'header5':'付属品',
    'data5':'なし',
    'header6':'動作温度',
    'data6':'-20℃ 〜 75℃',
    'header7':'相対湿度',
    'data7':'≤90%',
    'header8':'防水性能',
    'data8':'非対応',
    'header9':'耐火性能',
    'data9':'UL94V-0',
    'requirement':'流量センサーの要件',
    'open':'<p><a href="../pdf/IoTIZR_flowsensor_requirement.pdf" target="_blank"><i class="fa fa-file-pdf-o"></i> IoTIZR&trade;の流量センサー要件</a></p>',
    'status':'接続状況',
    'lead1':'<p>IoTIZR&trade;には、接続状況を示すLEDインジケータがあります。</p>',
    'light1':'ライトがついていない',
    'status1':'電源が入っていない/プラグが繋がっていない',
    'light2':'赤く点滅',
    'status2':'セットアップされていない/未接続',
    'light3':'緑色に点滅',
    'status3':'セットアップモード',
    'light4':'緑色に点灯',
    'status4':'ネットワークに接続されている',
    'safety':'安全上のご注意',
    'text1':'製品は非常に精密にできておりますので、お取り扱いに際しては十分注意してください。本製品のご使用に際しては、この「安全上のご注意」をよくお読みのうえ、正しくお使いください。以下は、あなたや他の人々への危害や財産への損害を未然に防ぎ、本製品を安全にお使いいただくために、守っていただきたい事項を示しています。',
    'warning':'警告',
    'lead2':'取り扱いを誤った場合、「死亡または重傷を負う可能性が想定される」内容を示します。',
    'warning1':'使用中に発煙や異臭、発熱などの異常が発生したときはすぐに使用をやめる。感電・火災の原因になります。',
    'warning2':'分解や改造、およびお客様による修理をしない。感電・火災・故障の原因になります。',
    'warning3':'火の中に投入したり、加熱したりしない。発火・火災・故障の原因になります。',
    'warning4':'高温になる場所(直射日光の当たる場所、暖房器具の近くなど)で使用・放置しない。発火・火災・故障の原因になります。',
    'warning5':'湿気やほこりの多い場所、油煙や湯気のあたる場所で使用・放置しない。感電・火災・故障の原因になります。',
    'warning6':'水の中に投入したり、ぬらしたりしない。また、ぬれた手で本製品に触れない。感電・火災・故障の原因になります。',
    'warning7':'本製品の内部に異物を入れない。感電・火災・故障の原因になります。',
    'warning8':'本製品は乳幼児やペットの手の届くところに置かない。誤って口にふくむと、怪我または窒息のおそれがあります。',
    'warning9':'ケーブル類は、コネクタに確実に奥まで差し込む。感電・火災・故障の原因になります。',
    'warning10':'ケーブル類は、コネクタに無理な力で挿入しない。内部の変形によるショートから、感電・火災・故障の原因になります。',
    'warning11':'変形したケーブル類を使わない。コネクタの破損によるショートやケーブルの断線から、感電・火災・故障などの原因になります。',
    'warning12':'コネクタは、むき出しの状態で使用しない。使用中に金属やほこりなどがふれてショートすることにより、感電・火災・故障などの原因になります。',
    'warning13':'本製品が破損(ひび、割れなど)しているのに気づいたら、使用をやめる。感電・火災の原因になります。',
    'warning14':'本製品を取り付ける場合は、しっかり固定する。固定が不十分だと、落下などでけがの原因になります。',
    'warning15':'本製品を取り付ける場合は、取り付け部分を定期的に点検する。時間の経過と共に、製品の取り付けにガタツキが出ていないか確認してください。固定が不十分だと落下などでけがの原因になります。',
    'caution':'ご注意',
    'lead3':'取り扱いを誤った場合、「傷害を負う可能性が想定される場合および物的損害の発生が想定される」内容を示します。',
    'caution1':'投げたり、落としたり、強い衝撃を与えたり、上に重いものを載せたりしない。故障の原因になります。',
    'usage':'使用上の注意',
    'lead4':'本製品は、一般的な飲食店や家庭での使用を目的とした製品です。本書および取扱説明書に記載されている以外の使用にて損害が発生した場合は、弊社は一切の責任を負いません。',
    'usage1':'医療機器や人命に直接的または間接的に関わるシステム、高い安全性や信頼性が求められる環境下で使用しないでください。',
    'usage2':'高い安全性や信頼性が要求される機器や電算機システムなどと直接的または間接的に関わるシステムでは使用しないでください。'
  },
  'issues': {
    'title':'問題は解決しましたか？',
    'contact':"問題が解決しない場合は、<a href=\'https://www.aplix.co.jp/inquiry/product/\'>お問い合わせください。</a>"
  },
  'price': {
    'title':'ご利用料金',
    'iotizr':'IoTIZR&trade;',
    'iotizr-desc':'IoTIZR&trade;本体のご購入',
    'sensors':'各種センサー',
    'sensors-desc':'取り付けるセンサーのご購入',
    'server':'クラウドサーバー',
    'server-desc':'クラウドサーバーのご利用料金',
    'total':'お問い合わせください',
    'total-desc':'ご利用台数に応じたご提案をいたします'
  },
  'footer': {
    'legal':"<a href=\'http://www.aplix.co.jp/legal/\' target='_blank' rel='noopener'>法務情報</a>",
    'policy':"<a href=\'http:///www.aplix.co.jp/privacypolicy/\' target='_blank' rel='noopener'>プライバシーポリシー</a>",
    'developer':"<a href=\'/www.aplix.co.jp/'\ target='_blank'>デベロッパー</a>"
  }
}
