module.exports = {
  'nav': {
    'lead':'All-in-One Package for Monitoring System',
    'about':'About',
    'introduction':'<i class="fa fa-file-pdf-o"></i> Introduction (document)',
    'howtojoin':'How to join',
    'howtojoin2':'How to join',
    'help':'Help &amp; Learning',
    'environment':'Supporting Environment',
    'quick':'Quick Start Guide',
    'basic':'Basic Operations',
    'faq':'FAQ',
    'items':'Items',
    'contact':'Contact',
    'signup':'Sign up',
    'device':'Device Information',
    'iotizr':'IoTIZR&trade;',
    'options': 'Options',
    'tds_tech_doc':'Simple TDS Sensor',
    'login':'Log in',
    'video':'Installtion Video',
    'flow':'Flow Sensor',
    'tds':'Simple TDS Sensor',
    'manual':'Users manual',
    'dashboard_manual':'Dashboard Users manual',
    'iotizr_manual':'IoTIZR&trade; Installation manual',
    'price':'Pricing'
  },
  'top': {
    'lead':'Connect with water, and your business will be more benefical',
    'text':'Smart monitoring with HARPS&trade;.'
  },
  'signup': {
    'title':'For Product Testers',
    'lead':'Do you have<br><span>HARPS&trade;α Tester’s Device kit?</span>',
    'text':'Let’s get started with',
    'signup':'Sign up'
  },
  'topAttention': {
    'title':'Attention!',
    'lead':'<p class="top-attention-lead">Current HARPS&trade; α version will end on March 22nd</p>',
    'text':'Learn more'
  },
  'main1': {
    'lead':'Ideal All-in-One Package for Internet Connected Water Treatment System',
    'life':'Filter life',
    'schedule':'Filter replacement schedule',
    'damage':'Filter damage status',
    'replace':'Time to replace'
  },
  'main2': {
    'lead':'What we provide in HARPS&trade;',
    'text1':'Hardware',
    'text2':'BLE-WLAN Transmitter "IoTIZR &trade;", Sensor',
    'text3':'Service',
    'text4':'Web App, Mobile App',
    'text5':'Option : Customization',
    'text6':'Change look and feel to your taste on the screens',
    'text7':'Connecting to your server',
    'text8':'Adding your specific sensor'
  },
  'main3': {
    'lead':'All the data will inspire your business.'
  },
  'main4': {
    'lead':'',
    'button':'Contact'
  },
  'attention': {
    'title':'Information',
    'lead':'For Product Testers',
    'text':'<p>Thank you for using HARPS&trade; α version. </p><p>Please be informed that the HARPS&trade; official version will open from April 1st.<br>We appreciated your cooperation being a monitor for HARPS&trade; α version.<br>Current HARPS&trade; α version will end on March 22nd and data migration from HARPS&trade; α version to HARPS&trade; official version is not possible.</p><p>We hope you will keep using HARPS&trade; service even after the HARPS&trade; official version open.<br>In order to do so, we will inform you the conditions (MOQ, price list and distributor agreement) separately with e-mail.</p><p>Thank you for your understandings.</p><p>Aplix Corporation.</p>',
    'back':'Back'
  },
  'about1': {
    'lead':'About HARPS&trade;',
    'head':'It\'s a Real All-in-One Package for Connected Water Systems',
    'box':'HARPS&trade; is internet connected water purification system that allows you to monitor your water usage anytime with your smartphone app and HARPS&trade; web site. It will help you manage filter replacement easier and cloud stored data and information will give you business opportunities.'
  },
  'about2': {
    'lead':'You can start immediately.',
    'text':'No hassle device adjustment, everything you need is here.',
    'item1':'Device',
    'item2':'Mobile App',
    'item3':'Dashboard'
  },
  'about3': {
    'title':'Device - IoTIZR&trade;',
    'text':'<p>IoTIZR&trade; is small-sized Bluetooth and Wireless LAN support connecting device. HARPS&trade; is designed to work with any flow sensor you use. By connecting your flow sensor and IoTIZR&trade;, your water filtering data will be sent to cloud through the internet.</p><p>Information from flow sensor will be sent to cloud from Wireless LAN access point at that place. You can access and check all the data IoTIZR&trade; collected with the Smartphone app and Web site.</p><p>Connecting flow sensor completes with just plugging in. HARPS&trade; Smartphone app allows you to set Wireless LAN access point without hassles.</p>'
  },
  'about4': {
    'title':'Mobile App',
    'text':'<p>iOS,Android supporting Smartphone app will be used for multiple purposes. This app is necessary for IoTIZR&trade; set-up. App download is required before start using.</p>',
    'usage1':'Required',
    'detail1':'<p>IoTIZR&trade; set-up, Wi-Fi access point change</p>',
    'detail2':'Filter replacement',
    'usage2':'As a mobile customer list',
    'detail3':'Notice will be displayed',
    'detail4':'As Address(Contact). Map application will pop up, when you tap on map.',
    'detail5':'You can check status of the water purification system any time.',
  },
  'about5': {
    'title':'Dashboard',
    'text':'Customer and filter status management.<br>You can check detail of the usage information.'
  },
  'contact': {
    'title':'Contact',
    'name':'Name',
    'email':'Email',
    'confirm':'Confirm Email',
    'company':'Company name',
    'position':'Position',
    'tel':'Telephone',
    'detail':'Details and comments',
    'submit':'Submit'
  },
  'thanks': {
    'text1':'Thank you for your contact.',
    'text2':'You will be contacted shortly.',
    'text3':'* Some inquiries may take a longer time to be responded. We apologize for your inconvenience.'
  },
  'environment': {
    'header1':'Target Water Treatment System',
    'data1':'Water filtering system with replaceable filters which life cycle is defined by hours used and/or amount of water filtered.',
    'header2':'Flow Sensor',
    'data2':"Savant FS-2200H-XHP ( <a href=\'http://tw.digisavant.com/PDF/FS-2200H-Spec.pdf\' target='_blank'>Specification</a> )<h4>Flow sensor above doesn’t work with your environment? Please <a href=\'https://www.aplix.co.jp/en/inquiry_en/product/\' target='_blank'>contact us</a> </h4>",
    'header3':'Mobile App compatibility',
    'data3iphone':'<h3>iPhone</h3><p>Requires iOS9.0 or later</p>',
    'data3android':'<h3>Android</h3><p>Requires Android4.4 or later</p><h4>* Malfunction may occur in some of the devices.</h4>',
    'header4':'Web App compatibility',
    'data4':'The latest version of Chrome, Safari, and Internet Explore.'
  },
  'quickMenu': {
    'step1':'Step 1',
    'step2':'Step 2',
    'step3':'Step 3',
    'step4':'Step 4',
    'step5':'Step 5',
    'step6':'Step 6',
    'step1Title':'Do you have<br>following items?',
    'step2Title':'Register your <br>purification system.',
    'step3Title':'Add your<br>System Location.',
    'step4Title':'Connect flow sensor<br>to Purifier.',
    'step5Title':'Set up<br>IoTIZR&trade;',
    'step6Title':'After Set up'
  },
  'step1': {
    'pdf':"<a href=\'../pdf/quickguide.pdf' target='_blank' class='pdf-link btn'><span>Quick Start Guide<br>PDF document</span>",
    'step':'Step1：Do you have following items?',
    'title1':'Flow sensor',
    'text1':"<p>Flow sensor is water amount measurement device. It will tell you used water amount when it’s connected to the water purification systems.</p><p>We are now providing Savant FS-2200H-XHP. Product detail is <a href=\'http://tw.digisavant.com/PDF/FS-2200H-Spec.pdf\' target='_blank'>here</a>.</p><p>When the FS-2200H-XHP doesn’t match to your environment (e.g. interconnecting part’s shape and size, or flow rate), other flow sensor will be needed. Please <a href=\'https://www.aplix.co.jp/en/inquiry_en/product/\' target='_blank'>contact</a>. </p>",
    'title2':'IoTIZR&trade;',
    'text2':'<p>IoTIZR&trade; allow things to connect to the internet. It is necessary to send data from flow sensor to the cloud and display status onto the Smartphone app and Web site.</p>',
    'title3':'Web App<br> "HARPS&trade; Dashboard"',
    'text4':"<p><a href=\'/aqua.harps4.com/dashboard/signup\' target='_blank'>Sign up</a> and follow the instruction to create your account.</p>",
    'title4':'Mobile App<br> "HARPS&trade; Toolkit"',
    'text5':'<p>Smartphone app is needed for IoTIZR&trade; setting, filter replacement and Wi-Fi access point change.<br> Download the app to your smartphone from followings and log in with HARPS&trade; account that you created at the web site.</p>',
    'nextStep':'Next step : Register your purification system.',
    'next':'Next'
  },
  'step2': {
    'step':'Step2 : Register your purification system.',
    'text1':'To adjust the filter replacement cycle as precise as possible, your water purification system’s detail(specification) need to be registered.',
    'text2':'Your water purification system’s detail registry is now available from our request form. Fill in the required information and send registry request. We will get back to you via email within a few days after your water system registry.',
    'request':'Request your Purification system',
    'text3':'If you firstly would like to test how it works before actual use, you can use preset water system.',
    'nextStep':'Next step : Add Location to install the water purification system.',
    'previous':'Previous',
    'next':'Next'
  },
  'step3': {
    'step':'Step3 : Add Location to install the water purification sytem.',
    'text1':'<p>Please register the address that the water purification system is installed. Address information is necessary for IoTIZR&trade; set-up. Register your location from here.</p>',
    'text2':'If you firstly would like to test how it works before actual use, register your office address or living address tentatively.',
    'add':'Add Location',
    'text3':'<p>Address registry can be made from the form above, also from Smartphone app as well. Although initial registry is recommended, can be also registered at the IoTIZR&trade; set up.</p>',
    'nextStep':'Next step : Connect flow sensor to purifier.',
    'previous':'Previous',
    'next':'Next'
  },
  'step4': {
    'step':'Step4 : Connect flow sensor to purifier.',
    'attention':'Following process should be operated at actual setting location.',
    'text1':'<p>Install a flow sensor to your water system. Then connect IoTIZR&trade; to the installed flow sensor, and put IoTIZR&trade; to place that away from water.</p>',
    'notice':'Notice',
    'text2':'Keep away from water or other liquid.',
    'nextStep':'<p class="next-step">Next step : Set up IoTIZR&trade;</p>',
    'previous':'Previous',
    'next':'Next'
  },
  'step5': {
    'step':'<h2 class="StepTitle">Step5 : Set up IoTIZR&trade;</h2>',
    'attention':'Following operation uses "HARPS&trade; Toolkit" smartphone app.',
    'text1':'<p>After downloading App from App Store/Google Play, log in with your HARPS&trade; account.</p>',
    'text2':'<p>By setting Wi-Fi access point to the IoTIZR&trade;, connect to the internet. Smartphone and IoTIZR&trade; need to be close to each other at the setting.</p>',
    'text3':'<p>Set up process will start by tapping “+Install” on smartphone app or pressing “H” button on the IoTIZR&trade;. Follow the displayed instructions and complete the settings/registry</p>',
    'nextStep':'Next step : After Set up',
    'previous':'Previous',
    'next':'Next'
  },
  'step6': {
    'step':'Step6 : After Set up',
    'text1':'<p>Status monitoring will start immediately after the IoTIZR&trade; set up is completed. Information will be updated every day.</p>',
    'title1':'Filter replacement:',
    'text2':'Tap “Filter replacement” button on Smartphone app.',
    'title2':'Wi-Fi access point change:',
    'text3':'Tap “Reset Wi-Fi Settings” button on Smartphone app.',
    'basic':"<p><a href=\'basic_operations.html\' class='large-link' target='_blank'>Basic Operations</a></p>",
    'previous':'Previous'
  },
  'basic': {
    'function':'HARPS&trade; Function',
    'text1':'<p>HARPS&trade; monitors status of water purification system and notifies of filter replacement timing and alerts. By installing IoTIZR&trade; to your water purification system, it will send the data of usage to the server within a certain period of time. Customers can check the usage status with Smartphone app and Web site.</p>',
    'related':'Related articles:',
    'device':'Device',
    'settings':'<h2>HARPS&trade; Settings</h2>',
    'quick':'See Quick Start Guide',
    'check':'Check filter status',
    'text2':'Choose location in the main screen, then select the filter in the location screen.',
    'app':'Mobile App',
    'replacement':'Filter replacement',
    'text3':'<p>When Filter\'s replacement due is past, notification will be displayed onto the Smarphone app / Web site.</p><p>To reset the filter life, manually tap “Filter Replacement” in the smartphone app. Filter life counter will be reset.</p>',
    'wifi':'Change Wi-Fi access point',
    'text4':'Wi-Fi access point can be changed from Wi-Fi Settings on Smartphone app.',
    'faq':'Can user of the water purification system change Wi-Fi access point setting?',
    'delete':'Delete registered water purification system',
    'text5':'To delete the registered water purification system, follow the instruction below.',
    'notice':'Notice',
    'text6':'If you want to reset IoTIZR&trade; completely including Wi-Fi access point, press the”H” button of the IoTIZR&trade;'
  },
  'faq': {
    'faq':'FAQ &amp; Trouble shooting',
    'words':'Words',
    'words1':'What is "Location"?',
    'words2':'What is "Active device(s)"?',
    'words3':'What is "Equipments"?',
    'words4':'HARPS&trade; Trial Water System',
    'operations':'Operations',
    'operations1':'I don\'t know how to monitor the status of water on the web screen.',
    'operations2':'Can water filtering system user/owner change the Wi-Fi access point?',
    'operations3':'The transmission interval of information from IoTIZR&trade;'
  },
  'trouble': {
    'trouble':'Trouble Shooting',
    'trouble1':'Even I check the HARPS&trade; web screen after running water, it is not reflected on parameta or graph.',
    'trouble2':'A red alert (telling "Please trun the settings on Location service") appears at the top of the app even though I turned on the location service.'
  },
  'answer': {
    'answer1':'“Location” means actual location where your water purification systems is installed. This location needs to be registered by water purification system owner. Location can be registered from Web site and Smartphone app.<br>Although initial registry is recommended, can be also registered at the IoTIZR&trade; set up from smarphone app.',
    'answer2':'“Active Device(s)” means IoTIZR&trade; that is being connected to the internet.',
    'answer3':'“Equipments” is divided into following two elements.<br> 1) Water purification systems parts<br> Including;<br><ul><li>Replacement filter</li><li>Filter canister, housing</li><li>Connecting device/parts</li></ul>If you’d like to just quick check the life cycle of the HARPS&trade;, choose HARPS&trade; Trial Water System.<br><br> 2)Flow sensor<br> HARPS&trade; α provides Savant FS-2200H-XHP as samples.',
    'answer4':'This water filtering system is pre-registered for testing purpose.<br>By choosing this virtual water filtering system, you can experience how HARPS&trade; works. Filter life is defined as 14days / 50gal or approx. 190litter',
    'answer5':'Currently, Wi-Fi access point can be changed only by water system vendor who can log in to App (HARPS&trade; Toolkit).',
    'answer6':'IoTIZR&trade; will continue to send information at short intervals for 10 minutes after turning on the power or after pressing the button. Usually, IoTIZR&trade; will send information every 15 minutes.',
    'answer7':"First, you need to sign up from <a href=\'https://aqua.harps4.com/dashboard/signup\' target='_blank'>Sign up form</a>.<br>Next, login to HARPS&trade; Dashboard from <a href=\'https://aqua.harps4.com/dashboard/login\' target='_blank' rel='noopener'>Log in page</a>.<br>Then please refer to <a href=\'../help/quickstart.html\' target='_blank'>Quick Start Guide</a> and proceed with setup.",
    'trouble1':'Even I check the HARPS&trade; web screen after running water, it is not reflected on parameta or graph.',
    'answer8':'After powering on the IoTIZR&trade;, or for ten minutes from pressing the button on the IoTIZR&trade;, the transmission interval of information from IoTIZR&trade; is every 15 minutes. To display the latest information on the HARPS&trade; web service, press the button of IoTIZR&trade;.',
    'trouble2':'A red alert (telling "Please trun the settings on Location service") appears at the top of the app even though I turned on the location service.',
    'answer9':'HARPS&trade; Toolkit requires that Location service setting is "Always". If your Location service is set to "While Using", please switch to "Always".'
  },
  'device': {
    'specifications':'Specifications',
    'header1':'I/F',
    'data1':'Port : one Water flow sensor port and three Extension sensor ports<br>LED : three LEDs<br>Button : one button',
    'header2':'Power supply',
    'data2':'micro USB B (Receptacle)<br>500mA or above',
    'header3':'Size',
    'data3':'L85mm * W50mm * H15mm (typical)',
    'header4':'Weight',
    'data4':'45g (typical)',
    'header5':'Option',
    'data5':'N/A',
    'header6':'Operating temperature',
    'data6':'-20℃ to 75℃',
    'header7':'Relative humidity',
    'data7':'≤90%',
    'header8':'Water proof',
    'data8':'N/A',
    'header9':'Fireproof level',
    'data9':'UL94V-0',
    'requirement':'Flow sensor requirement',
    'open':'<p><a href="../pdf/IoTIZR_flowsensor_requirement.pdf" target="_blank"><i class="fa fa-file-pdf-o"></i> Open "Flow sensor requirement for IoTIZR&trade;"</a></p>',
    'status':'Connectivity Status',
    'lead1':'<p>IoTIZR&trade; has LED indicators to show the connectivity status.</p>',
    'light1':'Light is off :',
    'status1':'No Power / Unplugged',
    'light2':'Blinking Red :',
    'status2':'No Setup / Lost Connection',
    'light3':'Blinking Green :',
    'status3':'Setup Mode',
    'light4':'Solid Green :',
    'status4':'Connected to network',
    'safety':'Safety Precautions',
    'text1':'Since products are made very precisely, please be careful when handling. Before using this product, please read this "Safety Precautions" carefully and use it properly. This document indicates items you need to follow in order to prevent harm to you or other people and damage to property and to use this product safely.',
    'warning':'Warning',
    'lead2':'If it is handled incorrectly, it indicates "The possibility of death or serious injury being assumed".',
    'warning1':'Discontinue use immediately when abnormality such as smoke, unusual smell, fever etc. occurs during use. It may cause electric shock or fire.',
    'warning2':'Do not disassemble, remodel, or repair by customer.It may cause electric shock, fire or malfunction.',
    'warning3':'Do not throw in a fire or heat it.It may cause fire, fire or malfunction.',
    'warning4':'Do not leave it in a place where it gets hot (direct sunlight, near a heater).It may cause fire, fire or malfunction.',
    'warning5':'Do not leave it in humid or dusty places, places exposed to oil smoke or steam.It may cause electric shock, fire or malfunction.',
    'warning6':'Do not throw in water or get wet. Also, do not touch this product with wet hands.It may cause electric shock, fire, or malfunction.',
    'warning7':'Do not insert foreign objects inside this product.It may cause electric shock, fire, or malfunction.',
    'warning8':'Do not place this product within reach of infants and pets.Incorrectly including it in a mouth may cause injury or suffocation.',
    'warning9':'Insert the cables surely into the connector until they are fully inserted.It may cause electric shock, fire or malfunction.',
    'warning10':'Do not insert cables into the connector with excessive force.From short circuit due to internal deformation, it may cause electric shock, fire, malfunction.',
    'warning11':'Do not use deformed cables.It may cause electric shock, fire, malfunction etc due to short circuit due to breakage of the connector or disconnection of the cable.',
    'warning12':'Do not use the connector in the exposed state.If you touch a metal or dust during use, short-circuiting may cause electric shock, fire, malfunction, etc.',
    'warning13':'If you notice that the product is broken (cracked, broken, etc.), stop using it.It may cause electric shock or fire.',
    'warning14':'When installing this product, secure it firmly.Failure to do so may result in injury due to falling etc.',
    'warning15':'When installing this product, periodically inspect the mounting part.As time goes by, please check if there is no rattling in the installation of the product.Failure to do so may cause injury due to falling etc.',
    'caution':'Caution',
    'lead3':'In the event of incorrect handling, it indicates the contents of "It is assumed that possibility of injury or physical damage may be assumed".',
    'caution1':'Do not throw, drop, give a strong shock, or put heavy things on top.It may cause a malfunction.',
    'usage':'Usage notes',
    'lead4':'This product is intended for use in general restaurants or homes. We do not assume any responsibility in the event of damage caused by use other than those described in this manual and instruction manual.',
    'usage1':'Do not use under direct or indirect system related to medical equipment or human life, in an environment requiring high safety and reliability.',
    'usage2':'Do not use in systems that directly or indirectly involve equipment or computer systems that require high safety or reliability.'
  },
  'issues': {
    'title':'Having other issues?',
    'contact':"If your issue is not fixed, please <a href=\'https://www.aplix.co.jp/en/inquiry_en/product/\' target='_blank'>contact us.</a>"
  },
  'join': {
    'contact':"If your issue is not fixed, please <a href=\'https://www.aplix.co.jp/en/inquiry_en/product/\' target='_blank'>contact us.</a>"
  },
  'price': {
    'title':'Pricing',
    'iotizr':'IoTIZR&trade;',
    'iotizr-desc':'Please purchase IoTIZR&trade;(s) and USB AC adapter(optional)',
    'sensors':'Sensors',
    'sensors-desc':'Please purchase sensors to connect to IoTIZR&trade;(s)',
    'server':'Cloud server',
    'server-desc':'Cloud server usage fee',
    'total':'Contact us',
    'total-desc':'We will propose according to the number of units you use'
  },
  'footer': {
    'legal':"<a href=\'/www.aplix.co.jp/en/legal/\' target='_blank' rel='noopener'>Legal</a>",
    'policy':"<a href=\'/www.aplix.co.jp/en/privacypolicy/privacypolicy_iot.php\' target='_blank' rel='noopener'>Privacy Policy</a>",
    'developer':"<a href=\'/www.aplix.co.jp/en/'\ target='_blank'>Developer</a>"
  }
}
