const config = {
  // accessKey: '',
  // secretKey: '',
  // sessionToken: '',
  // region: '',
  apiKey: 'E3GPSpu284aXNOoukAydxa81G3qhlJfn5L2x1LIX',
  // defaultContentType: 'application/json',
  // defaultAcceptType: 'application/json'
};

export default apigClientFactory.newClient(config);
