import contact from './contact.js';
import feedback from './feedback.js';
import language from './language.js';

const routes = [
  { path: '^/contact\.html',  handler: contact  },
  { path: '^/feedback\.html', handler: feedback },
];

const dispatchEvent = (event, ...args) => {
  const path = window.location.pathname;
  routes
    .filter((route) => {
      let reg;
      if ((typeof route.path) === 'string') {
        reg = new RegExp(route.path);
      } else {
        reg = route.path;
      }
      return reg.test(path) && ((typeof route.handler[event]) === 'function');
    })
    .map((route) => {
      try {
        route.handler[event](...args);
      } catch (e) {
        console.error(e);
      }
    });
};

window.onload = function() {
  dispatchEvent('onload');
};

window.addEventListener('resize', function(event) {
  dispatchEvent('onresize');
});

window.recaptchaInit = function() {
  if (grecaptcha) {
    dispatchEvent('recaptchaInit');
  }
};



// Create VueI18n instance with options
const data = require('./translations/translations.js');
const i18n = new VueI18n({
  locale: language.getLanguage(),
  fallbackLocale: 'en',
  messages: data, // set locale messages
});

new Vue({
  el: '#app',
  i18n: i18n,
  data: {
    message: 'Hello Vue.js!'
  },
  computed: {
    contactUrl() {
        if (this.$i18n.locale === 'ja') {
            return 'https://www.aplix.co.jp/inquiry/product/'
        } else {
            return 'https://www.aplix.co.jp/en/inquiry_en/product/'
        }
    }
  },
  methods: {
    changeLanguage(e) {
      const selectedLanguage = e.target.value;
      // ローカルストレージに保存
      language.setLanguage(selectedLanguage);
      // 翻訳ライブラリ i18nに格納・反映
      this.$i18n.locale = selectedLanguage;
      console.log(language.getLanguage());
    }
  }
});
