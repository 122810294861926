import HarpsApi from './harps-api';

const onSubmit = function(token) {
  // console.log(token);

  const $contactForm = $('form#contact01');
  const $loader = $('#quick_loader');

  const params = $contactForm.serializeArray().reduce((carry, item) => {
    carry[item.name] = item.value;
    return carry;
  }, {});

  $contactForm.find('input, textarea, button, select').prop('disabled', true);
  $loader.show();

  const additionalParams = {
    headers: {
      'x-harps4-client-id': 'contact_form'
    }
  };

  HarpsApi.inquiriesPost(null, { data: params }, additionalParams)
    .then(function(result) {
      // console.log(result);
      location.replace('./contact_thanks.html');
    })
    .catch(function(err) {
      // console.log(err);
      alert('An internal server error occurred. Please try again later.');
      // location.reload();
    })
    // finally
    .then(function() {
      $loader.hide();
      $contactForm.find('input, textarea, button, select').prop('disabled', false);
    });
};

const adjustRecaptchaPosition = () => {
  const $recaptcha = $('.grecaptcha-badge');
  const $footer = $('footer');
  $recaptcha.css('bottom', ($footer.height() + Math.floor($recaptcha.height() / 2) + 3) + 'px');
}

export default {

  onload: () => {
    // console.log('contact:onload');
    adjustRecaptchaPosition();

    // gentelella >>
    if (validator) {
      validator.message.date = 'not a real date';

      $('form')
        .on('blur', 'input[required], input.optional, select.required', validator.checkField)
        .on('change', 'select.required', validator.checkField)
        .on('keypress', 'input[required][pattern]', validator.checkField);

      $('.multi.required')
        .on('keyup blur', 'input', function() {
          validator.checkField.apply($(this).siblings().last()[0]);
        });
    }
    // gentelella <<

    const $contactForm = $('form#contact01');

    $contactForm.on('submit', function(e) {
      e.preventDefault();

      if (validator && !validator.checkAll($contactForm)) {
        return false;
      }

      grecaptcha.execute();

      return false;
    });
  },

  onresize: () => {
    // console.log('contact:onresize');
    adjustRecaptchaPosition();
  },

  recaptchaInit: () => {
    // console.log('contact:recaptchaInit');
    const $submit = $('#send');
    grecaptcha.render($submit.get(0), {
      'sitekey' : '6LfwEjkUAAAAAKSzEQWsaxxbT-7erZ3Yc4FWDdTC',
      'callback' : onSubmit,
    });
    adjustRecaptchaPosition();
  },
}
