'use strict';

const getBrowserLanguage = () => {
  return (window.navigator.languages && window.navigator.languages[0])
      ||  window.navigator.language
      ||  window.navigator.userLanguage
          window.navigator.browserLanguage;
};

export default {

  getLanguage: () => {
    // ローカルストレージ設定を最優先
    if (window.localStorage) {
      try {
        const language = window.localStorage.getItem('userLanguage');
        if (language) {
          return JSON.parse(language);
        }
      } catch (e) {
        console.log(e);
      }
    }
    // 初回アクセスはブラウザ言語設定を使用する
    return getBrowserLanguage();
  },

  setLanguage: (language) => {
    if (window.localStorage) {
      try {
        window.localStorage.setItem('userLanguage', JSON.stringify(language));
      } catch (e) {
        console.log(e);
      }
    }
  }

};
